<template>
  <div class="about3">
    <div data-v-2ac32f0d class="van-nav-bar van-nav-bar--fixed">
      <div class="van-nav-bar__left" v-on:click="back">
        <i class="van-icon van-icon-arrow-left van-nav-bar__arrow">
          <!---->
        </i>
      </div>
      <div class="van-nav-bar__title van-ellipsis">{{ langs.t1 }} </div>
      <div class="van-nav-bar__right" v-on:click="to_list">{{ text2[lang] }}</div>
    </div>
    <div data-v-52129574 data-v-65207978 class="PageBox">
      <div data-v-52129574 class="ScrollBox">
        <div data-v-52129574>
          <div data-v-52129574>
            <div data-v-52129574 role="separator" class="van-divider van-divider--hairline van-divider--content-left">
              <span data-v-52129574>
                {{ langs.t2 }}
              </span>
            </div>
            <!---->
          </div>
        </div>
        <!---->
      </div>
    </div>
    <div style="width:92%;margin:0 auto" class="baise">
      <!-- <van-field
    v-model="usdt"
     name="usdt1"
    :label="text10[lang]"
     type="number"
     :formatter="formatter"
    :rules="[{ required: true, message: '' }]" style=""
  /> -->
      <van-field v-model="price" name="price" type="digit"    @blur="init_price" :label="langs.t3" :placeholder="langs.t4"
        :rules="[{ required: true, message: '' }]" style="" />


      <!--payment-->
      <!-- <van-button
        color="#7232dd"
        type="primary"
        size="large"
        style="margin-top:20px"
            @click="payment(122)"
      >Mobile Wallets 2</van-button> -->


      <!-- payvip-->

      <div v-for="(item, index) in zhifu" :key="index">
        <van-button v-if="item.id == 10" color="#ff8917" type="primary" size="large" style="margin-top:20px"
          @click="wepay_bank('one_pay')">{{ langs.t5 }} {{item.sort}}</van-button>


        <!--Bank card wepay -->
        <van-button v-if="item.id == 11" color="#ff8917" type="primary" size="large" style="margin-top:20px"
         @click="wepay_bank('jypay')">{{ langs.t5 }} {{item.sort}}</van-button>

         <van-button v-if="item.id == 12" color="#ff8917" type="primary" size="large" style="margin-top:20px"
         @click="wepay_bank('dypap')">{{ langs.t5 }} {{item.sort}}</van-button>
      </div>


      <!-- <van-button
        color="#7232dd"
        type="primary"
        size="large"
        style="margin-top:20px"
        @click="wepay_bank(151)"
      >Mobile Wallets  1</van-button> -->

      <!--payment-->
      <!-- <van-button
        color="#7232dd"
        type="primary"
        size="large"
        style="margin-top:20px"
            @click="payment(102)"
      >Mobile Wallets 2</van-button> -->

      <!-- <van-button
        color="#7232dd"
        type="primary"
        size="large"
        style="margin-top:20px"
       @click="payment(122)"
      >Mobile Wallets 1</van-button> -->

      <!-- <van-button
        color="#7232dd"
        type="primary"
        size="large"
        style="margin-top:20px"
            @click="payment(102)"
      >Mobile Wallets 2</van-button>  -->



      <!-- payvip-->
      <!-- <van-button
        color="#7232dd"
        type="primary"
        size="large"
        style="margin-top:20px"
        @click="wepay_y1"
      >Pay 2 electronic wallet</van-button> -->


    </div>
    <div class="txsm">
      <p style="white-space:pre-wrap;text-align: left;color:#999;padding: 10px;">{{ cz_msg }}</p>
    </div>


    <div style="width: 96%;margin: 0 auto;margin-top: 150px;">

  
    <!-- <video  
      controls  
      width="100%"  
      height="auto"  
      src="https://storage.googleapis.com/onepay-storage/checkout/bkash.MP4"  

    >  
      Your browser does not support the video tag.  
    </video> -->


    </div>



    <Footer :lang_list="lang_list" :lang="langs" />
  </div>
</template>
<script>
import Vue from "vue";
import { Button } from "vant";
import { Toast } from "vant";
import * as api from "@/api";
import * as local from "@/libs/local";
import Footer from './lyout/footer.vue';
Vue.use(Button);
export default {
  name: "chz",
  components: {
    //HelloWorld
    Footer,
  },
  data() {
    return {
      radio: "1",
      price: 10000,
      usdt: 1.00,
      huilv: 0,
      zhifu: [],
      loading: false,
      lock: false,
      cz_msg: "",
      lang: 0,
      text1: ["Deposit", "مشحونة", "Chargement"],
      text2: ["Deposit record", "سجل شحن", "Enregistrement"],
      text3: [" Please select a payment method to pay ", "الرجاء اختيار طريقة الدفع", "Veuillez sélectionner un mode de paiement"],
      text4: ["Amount", "الكمية", "Quantité"],
      text5: ["Please enter the deposit amount", "من فضلك أدخل مبلغ الشحن", "Veuillez entrer le montant de la recharge"],
      text6: ["Bank card payment", "الدفع بواسطة بطاقة البنك", "Paiement par carte bancaire"],
      text7: ["Bank card payment1", "الدفع بواسطة بطاقة البنك", "Paiement par carte bancaire 1"],
      text8: ["Please enter the amount you want to top-up and click the choose payment method to complete the top-up",
        "أدخل المبلغ الذي تريد شحن ، ثم انقر فوق اختيار طريقة الدفع لإكمال شحن"
        , "Veuillez entrer le montant que vous souhaitez recharger et cliquez sur Sélectionner un mode de paiement pour terminer le rechargement"],
      text9: ["The amount you top-up will give you cash equivalents USDT",
        "المبلغ الذي كنت تهمة سوف تعطيك ما يعادل usdt النقدية"
        , "Le montant que vous rechargez vous donnera l'équivalent en espèces usdt"],
      text10: ["Booked USDT", "كتاب USDT", "Déjà réservé usdt"],
      lang_list: [],
      select_lang: {},
      langs: {},
      cate: [],
    };
  },
  created() {
    console.log(this.$route.query);
    this.lang = local.readInfo('setting_lang') == undefined || local.readInfo('setting_lang') == null ? 0 : local.readInfo('setting_lang')
    this.get_price()
  },
  methods: {
    init_price:function(event){
    console.log(event)
    if(this.price<10000){
      this.price=10000
    }
  },
    get_price() {
      console.log(this.huilv)
      if (this.usdt > 0) {
        if (this.huilv > 0) {
          //   this.price=(this.usdt*this.huilv).toFixed(4)

        } else {
          api.get_huilv({
            token: local.readInfo('jwt'),
            lang: local.readInfo('car_lang'),
          }, (err, data) => {
            this.logining = false
            if (!err && data.code === 1) {
              this.lang_list = data.lang_list;
              this.select_lang = data.select_lang;
              this.langs = data.lang;
              this.huilv = data.data.huilv
              this.zhifu = data.data.zhifu
              this.cz_msg = data.data.cz_msg
              //this.usdt=((this.price*10000/this.huilv)/10000).toFixed(6)
              //   this.price=(this.usdt*this.huilv).toFixed(4)
            }

          }
          )
        }
      }
    },
    formatter: function (x) {
      console.log(x)
      if (x >= 0) {
        this.get_price()
        return x
      }


    },
    back() {
      this.$router.go(-1); //返回上一层
    },
    to_list() {
      let _this = this;
      _this.$router.push({ name: "cz_history", query: { redId: 1111 } });
    },



    wepay_bank: function (code) {
      if (this.price < 100) {
        Toast('Please input the top-up amount')
        return
      }
      Toast.loading({
        message: "loading...",
        forbidClick: true
      });

      if (code === "one_pay") {
        api.all("qintai_public/api.user1/get_onepay",
          {
            token: local.readInfo("jwt"),
            pay_type: code,
            id: 0,
            price: this.price
          },
          (err, data) => {
            if (!err && data.code === 1) {
              Toast.loading({
                message: "loading...",
                forbidClick: true
              });
              window.location.href = data.data.paymentUrl;
            } else {
              console.log(data);
              if (data && "msg" in data) {
                Toast(data.msg);
              }
              // this.logining = false
            }
          }
        );
      }
      if (code === "jypay") {
        api.all("qintai_public/api.user1/get_jypay",
          {
            token: local.readInfo("jwt"),
            pay_type: code,
            id: 0,
            price: this.price
          },
          (err, data) => {
            if (!err && data.code === 1) {
              Toast.loading({
                message: "loading...",
                forbidClick: true
              });
             window.location.href = data.data.orderData;
            } else {
              console.log(data);
              if (data && "msg" in data) {
                Toast(data.msg);
              }
              // this.logining = false
            }
          }
        );
      }


      if (code === "dypap") {
        api.all("qintai_public/api.user1/get_dypap",
          {
            token: local.readInfo("jwt"),
            pay_type: code,
            id: 0,
            price: this.price
          },
          (err, data) => {
            if (!err && data.code === 1) {
              Toast.loading({
                message: "loading...",
                forbidClick: true
              });
             window.location.href = data.data.orderData;
            } else {
              console.log(data);
              if (data && "msg" in data) {
                Toast(data.msg);
              }
              // this.logining = false
            }
          }
        );
      }



    }
  }

};
</script>
<style scoped>
/* .baise>>>.van-field__label{color:white}
.baise>>>.van-field__control{color:white} */

.Main {
  position: relative;
}

.Main {
  width: 100%;
  height: 100%;

}

.IndexBox {

  /* padding-bottom: 64px; */
}

.IndexBox,
.ScrollBox {
  position: relative;
  width: 100%;
  height: 100%;
}

.ScrollBox {
  overflow-x: hidden;
  overflow-y: auto;
}

.van-nav-bar {
  background-color: transparent !important;
  color: white !important;
}

.van-nav-bar {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 46px;
  line-height: 1.5;
  text-align: center;
  background-color: #fff;
  -webkit-user-select: none;
  user-select: none;
}

.van-nav-bar__left {
  left: 0;
  padding: 0 12px;
}

.van-nav-bar__left {
  left: 0;
}

.van-nav-bar__left,
.van-nav-bar__right {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 16px;
  font-size: 14px;
  cursor: pointer;
}

.van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #323233;
  font-weight: 500;
  font-size: 16px;
}

.van-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.van-nav-bar__right {
  right: 0;
  padding: 0 12px;
}

.van-nav-bar__right {
  right: 0;
}

.van-nav-bar__left,
.van-nav-bar__right {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 16px;
  font-size: 14px;
  cursor: pointer;
}

.about3 {
  min-height: 100vh;
  width: 100%;
  height: auto;
  background: #f7fbe1;
  background-size: 100%;
}
</style>